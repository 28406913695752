<template>
  <div>
    <div :class="$style.appeals">
      <Title text="Обращения" position="right" />
      <div :class="$style.appeals__header">
        <Input :class="$style.appeals__header_input" placeholder="Поиск" />
      </div>
      <div :class="$style.table">
        <div :class="$style.table__header_row">
          <div
            :class="$style.table__header_column"
            v-for="headerColumn in headerColumns"
            :key="headerColumn.id"
          >
            {{ headerColumn.title }}
            <img v-if="headerColumn.value" src="@/assets/icons/arrow.svg" alt="arrow" />
          </div>
        </div>
        <div :class="$style.table__content">
          <div
            v-for="appeal in totalItems"
            :key="appeal.id"
            :class="$style.table__content_row"
          >
            <div
              :class="[
                $style.table__content_column,
                {
                  [$style.content__item_active]: appeal.viewed === false
                }
              ]"
            >
              {{ appeal.id }}
            </div>
            <div :class="$style.table__content_column">
              {{ moment(appeal.createdAt).format('DD-MM-YYYY  LT') }}
            </div>
            <div :class="$style.table__content_column">
              {{ !appeal.viewed ? 'Не прочитано' : 'Прочитано' }}
            </div>
            <div v-if="appeal.typeId == 12" :class="$style.table__content_column">
              {{ appeal.type.name }}
              Предмет: {{ JSON.parse(appeal.data).subject.name }} Телефон:
              {{ JSON.parse(appeal.data).user.phone }}
            </div>
            <div v-if="appeal.typeId == 18" :class="$style.table__content_column">
              {{ appeal.type.name }}
              Телефон:
              {{ JSON.parse(appeal.data).phone }}
              Имя:
              {{ JSON.parse(appeal.data).name }}
            </div>
            <div :class="$style.table__content_column">
              <Button @click="setPost(appeal.id)" v-if="appeal.viewed === false" type="table">
                Прочитано
              </Button>
            </div>
          </div>
          <div :class="$style.content__wrapperLine">
            <div :class="$style.content__line" />
            <div :class="$style.content__line" />
            <div :class="$style.content__line" />
            <div :class="$style.content__line" />
            <div :class="$style.content__line" />
          </div>
        </div>
      </div>
    </div>
    <pagination
      :pageCount="pageCount"
      :prevText="''"
      :click-handler="onPageChange"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      v-show="appeals.length > 10"
      :initial-page="this.$route.query.page - 1"
    />
  </div>
</template>

<script>
import Title from '@/basic/Title'
import Input from '@/basic/Input'
import Button from '@/basic/Button'

import appeals from '@/api/appeals'
import paginationMixin from '@/mixins/pagination.mixin'

import Moment from 'moment'
import orderBy from 'lodash/orderBy'

export default {
  name: 'Appeals',
  components: {
    Title,
    Input,
    Button
  },
  data() {
    return {
      appeals: [],
      headerColumns: [
        {
          id: 1,
          value: 'id',
          title: 'id'
        },
        {
          id: 2,
          value: 'datetime',
          title: 'Дата'
        },
        {
          id: 3,
          value: 'statusId',
          title: 'Статус'
        },
        {
          id: 4,
          value: 'teacher',
          title: 'Преподаватель'
        },
        {
          id: 5,
          value: 'student',
          title: 'Ученик'
        }
      ]
    }
  },
  mixins: [paginationMixin],

  async mounted() {
    const res = await appeals.getNotifications()
    this.appeals = orderBy(res.data.result, 'id', this.sortMethod)
    this.setupPagination(this.appeals)
  },
  watch: {
    filterValue: {
      handler() {
        this.setupPagination(this.appeals)
      }
    }
  },
  computed: {
    moment() {
      return Moment
    }
  },

  methods: {
    async setPost(id) {
      // const res = await appeals.setPost(this.appeals.find((el) => el.id == id).id)
      const appeal = this.appeals.find((el) => el.id == id)

      if (appeal.typeId == 12) {
        this.$router.push({
          name: 'NewOrder',
          params: {
            user: {
              id: JSON.parse(appeal.data).user.id,
              value: JSON.parse(appeal.data).user.phone
            },
            subject: {
              id: JSON.parse(appeal.data).subject.id,
              value: JSON.parse(appeal.data).subject.name
            }
          }
        })
      } else if (appeal.typeId == 18) {
        await appeals.setPost(this.appeals.find((el) => el.id == id).id)
      }
    },
    sortAppeals(sortValue) {
      this.toggleSortMethod()
      this.appeals = orderBy(this.appeals, sortValue, this.sortMethod)
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    },
    setCurrentPage(pageNumber) {
      this.currentPage = pageNumber
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.appeals {
  &__header {
    display: flex;
    margin: 0 0 2rem;
    &_input {
      width: 15rem;
      margin: 0 4rem 0 0;
    }
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 8%;
        }

        &:nth-child(2) {
          width: 16%;
        }

        &:nth-child(3) {
          width: 16%;
        }

        &:nth-child(4) {
          width: 35%;
        }

        &:last-child {
          width: 25%;
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3rem;
        border-bottom: 1px solid $table-border;
        &_active {
          background: $red;
          border-right: 1px solid $table-border;
        }
      }

      &_column {
        display: flex;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 8%;
          justify-content: center;
        }

        &:nth-child(2) {
          width: 16%;
        }

        &:nth-child(3) {
          width: 16%;
        }

        &:nth-child(4) {
          width: 35%;
        }

        &:last-child {
          width: 25%;
          border: none;
        }
      }

      &__wrapperLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &__line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 23.3%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
